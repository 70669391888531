@import "../../colors.scss";
@import "../../mixins.scss";

.hamburgerButton {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 48px;
  justify-content: center;
  user-select: none;
  width: 48px;
  z-index: 100;
}

@include medium-devices {
  .hamburgerButton {
    display: none;
  }
}

.hamburgerButton span {
  height: 2px;
  left: 0;
  width: 18px
}

.hamburgerButton span:first-child {
  display: block;
  margin-bottom: 5px;
  // top: 5px;
}

.dark span {
  background: $dark-theme-text-color-1;
}

.light span {
  background: $light-theme-line-color-1;
  background: $light-theme-line-color-2;
}

/* Burger Close */
.isNotToggled span:first-child {
  animation: smashDownReverse 0.5s 1 forwards;
}

.isNotToggled span:nth-child(2) {
  animation: burgerVomit 0.25s 1 forwards;
}

.isNotToggled span:last-child {
  animation: smashUpReverse 0.5s 1 forwards;
}

.isToggled span:first-child {
  animation: smashDown 0.5s 1 forwards;
  margin-bottom: -2px;
}

.isToggled span:nth-child(2) {
  animation: burgerEaten 0.25s 1 forwards;
}

.isToggled span:last-child {
  animation: smashUp 0.5s 1 forwards;
}

@keyframes smashDown {
  0% {
    top: 5px;
  }

  50% {
    top: 15px;
    transform: rotate(0deg);
  }

  100% {
    top: 15px;
    transform: rotate(45deg);
  }
}

@keyframes burgerEaten {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes smashUp {
  0% {
    bottom: 5px;
  }

  50% {
    bottom: 15px;
    transform: rotate(0deg);
  }

  100% {
    bottom: 15px;
    transform: rotate(-45deg);
  }
}

@keyframes smashDownReverse {
  0% {
    top: 15px;
    transform: rotate(45deg);
  }

  50% {
    top: 15px;
    transform: rotate(0deg);
  }

  100% {
    top: 5px;
  }
}

@keyframes burgerVomit {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes smashUpReverse {
  0% {
    bottom: 15px;
    transform: rotate(-45deg);
  }

  50% {
    bottom: 15px;
    transform: rotate(0deg);
  }

  100% {
    bottom: 5px;
  }
}

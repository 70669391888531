@import "../../mixins.scss";
@import "../../colors.scss";

.menu {
  flex-shrink: 0;
  height: 100vh;
  left: -250px;
  overflow: auto;
  padding: 20px;
  position: absolute;
  top: 0;
  transition: left 250ms;
  width: 250px;
  z-index: 10;
}

.openLayout {
  backdrop-filter: blur(5px);
  display: block;
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100%;
  @include medium-devices {
    display: none;
  }
}

.dark {
  background: $dark-theme-background-color;
  border-right: 1px solid $dark-theme-line-color-1;
}

.light {
  background-color: $light-theme-background-color;
  border-right: 1px solid $light-theme-line-color-1;
}

.menu.open {
  left: 0;
}

.sectionHeading {
  margin: 20px 0;
}

.linkWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .link {
    font-size: .83em;
  }
}

@include medium-devices {
  .menu {
    left: 0;
    position: relative;
    top: 0;
    z-index: 0;
  }
}

.title {
  margin-bottom: 20px;
}

@import "../../colors.scss";

.text {
  margin-bottom: 0;
  margin-top: 0;
}
.dark {
  color: $dark-theme-text-color-2;
}

.light {
  color: $light-theme-text-color-2;
}

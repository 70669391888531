
$dark-theme-background-color: rgb(28, 28, 28);
$dark-theme-line-color-1: rgb(46, 46, 46);
$dark-theme-line-color-2: rgb(112,112,112);
$dark-theme-text-color-1: rgb(237, 237, 237);
$dark-theme-text-color-2: rgb(187, 187, 187);

$light-theme-background-color: rgb(248, 249, 250);
$light-theme-line-color-1: rgb(230, 232, 235);
$light-theme-line-color-2: rgb(136,144,150);
$light-theme-text-color-1: rgb(104, 112, 118);
$light-theme-text-color-2: rgb(17, 24, 28);

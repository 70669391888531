@import "../../colors.scss";

.heading {
  margin: 0;
}

.dark {
  color: $dark-theme-text-color-1;
}

.light {
  color: $light-theme-text-color-2
}

@import "../../colors.scss";

.link {
  text-decoration: none;
  transition: color 250ms;

  &.dark {
    color: $dark-theme-text-color-2;

    &:hover {
      color: $dark-theme-text-color-1;
    }
  }

  &.light {
    color: $light-theme-text-color-2;

    &:hover {
      color: $light-theme-text-color-1;
    }
  }
}
